@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 200;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-Light-91c1d48c1c2e0de4fac4355a48849f51.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-Light-caca7f67984c51e14268d4ff0b9a1678.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 200;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-LightItalic-1cd8f6281468a6e48ec951c447f8ea5e.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-LightItalic-6ed33b221f2aee00a244628bf0a784a8.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 300;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-Book-64f78b6935f3d3c23ef0d72d3a6efe05.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-Book-c827673be2f0f2932d9ddb43b9dd90ed.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 300;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-BookItalic-2f125d2eaea29d77a7633e6b6c49ccb9.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-BookItalic-894bc85e0ea7c2553b857a80f5ba5f14.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 400;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-Regular-2987046e502412ca686e31ca23724346.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-Regular-c7ddde8ad05ff55bf20610a628c128a7.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 400;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-RegularItalic-91a7bb44ce93689974ac72e586179e9c.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-RegularItalic-0205c91c5237ad0912db457d096b8018.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 500;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-Medium-c05de14194f7d45e9a71221236239cb9.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-Medium-ac0d025f16e00a3fb9d2c385999841ba.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 500;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-MediumItalic-c9293b953a07b79968819e1f764dfbad.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-MediumItalic-cb56283107bd4eb4080b61aefdefb555.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: normal;
  font-variant: normal;
  font-weight: 600;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-Bold-610cf792bc97369fd3e98ae2b1582db8.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-Bold-722644c94e4a4940606a3c5921e0dcfa.woff')
      format('woff');
}

@font-face {
  font-display: fallback;
  font-family: ABCFavorit;
  font-stretch: normal;
  font-style: italic;
  font-variant: normal;
  font-weight: 600;
  src:
    url('https://assets.nydig.com/fonts/ABCFavorit-BoldItalic-7019023beb8dea138676e4734b44ff5a.woff2')
      format('woff2'),
    url('https://assets.nydig.com/fonts/ABCFavorit-BoldItalic-5bb5cd7615c0f7934b798282ca2cbe75.woff')
      format('woff');
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .animation-delay-10 {
    animation-delay: 0.1s;
  }
  .animation-delay-20 {
    animation-delay: 0.2s;
  }
  .animation-delay-30 {
    animation-delay: 0.3s;
  }
}

* {
  line-height: 1.1;
}

html {
  -webkit-tap-highlight-color: transparent;
}
